import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import './InputBloc.styl';

const InputBloc = ({
    autoComplete,
    defaultValue,
    disabled,
    errors,
    label,
    name,
    options,
    placeholder,
    register,
    required,
    tag,
    type,
}) => {
    function renderTag() {
        // const options = {};
        if (required === true) {
            options.required = 'Ce champ est requis';
        } else {
            options.required = false;
        }
        const props = {
            autoComplete,
            defaultValue,
            disabled,
            name,
            placeholder,
            ref: register(options),
            type,
        };

        switch (tag) {
            case 'textarea':
                return <textarea {...props} />;
            default:
                return (
                    <input
                        {...props}
                        // placeholder={label}
                        // className={clsx('input-error')}
                        className={clsx({ 'input-error': errors[name] })}
                        aria-label={label}
                    />
                );
        }
    }

    return (
        <div className='input-bloc' name={name}>
            <label htmlFor={name} className='h3-like'>
                {label}
            </label>
            {renderTag()}

            <div className='error-bloc'>{errors[name] && errors[name].message}</div>
        </div>
    );
};

InputBloc.defaultProps = {
    autoComplete: 'on',
    defaultValue: '',
    disabled: false,
    errors: null,
    options: {},
    placeholder: '',
    required: true,
    tag: null,
    type: 'text',
};

InputBloc.propTypes = {
    autoComplete: PropTypes.string,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.object,
    placeholder: PropTypes.string,
    register: PropTypes.func.isRequired,
    required: PropTypes.bool,
    tag: PropTypes.string,
    type: PropTypes.string,
};
export default InputBloc;
