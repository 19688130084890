import isEmail from 'validator/es/lib/isEmail';

const fields = () => ({
    email: {
        iconName: 'Mail',
        label: 'Adresse Mail',
        name: 'email',
        options: {
            validate: {
                format: (value) => isEmail(value) || "Le format du mail n'est pas valide",
            },
        },
        placeholder: 'ex : jeandupont@mail.com',
        type: 'email',
    },
    firstName: {
        iconName: 'User',
        label: 'Prénom',
        name: 'firstname',
        options: {
            required: 'Ce champ est requis',
        },
        placeholder: 'ex : Jean',
    },
    lastName: {
        iconName: 'User',
        label: 'Nom',
        name: 'lastname',
        options: {
            required: 'Ce champ est requis',
        },
        placeholder: 'ex : Dupont',
    },
    login: {
        iconName: 'Login',
        label: 'login',
        name: 'login',
        options: {
            required: 'Ce champ est requis',
        },
        placeholder: 'ex : administateur',
    },
    password: {
        iconName: 'Password',
        label: 'Mot de passe',
        name: 'password',
        placeholder: 'ex : M0nM0t2P@sse',
        type: 'password',
    },
    phone: {
        iconName: 'Phone',
        label: 'Numéro de téléphone',
        name: 'phone',
        placeholder: 'ex : 0635578996',
        type: 'tel',
    },
});

export default fields;
