import PropTypes from 'prop-types';
import React from 'react';

import './FormError.styl';

const FormError = ({ errorMessages, formError }) => {
    return formError ? <div className='form-error'>{errorMessages[formError.response.status]}</div> : null;
};

FormError.propTypes = {
    errorMessages: PropTypes.object.isRequired,
    formError: PropTypes.object,
};

export default FormError;
